.App {
  display: flex;
  text-align: center;
  min-height: 100vh;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  color: white;
}
.login {
  text-align: center;
  min-height: 100vh;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.title{
  font-size: large;
}
.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  border-bottom-style: solid;
  border-top-style: solid;
  border-left-style: solid;
  color: white;
  border-color:bisque;
}
.inputBox{
  display: flex;
  flex-direction: column;
  /* margin-left: 1vmin; */
  /* border-right-style: solid; */
  padding-left: 1vmin;
  padding-right: 1vmin;
  padding-bottom: 2vmin;
  color: white;
}
.generateWarning{
  height: 100px;
  max-height: 100px;
  max-width: 177px;
  resize: none;
  color: red;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  /* text-align: center; */
  margin-top: auto;
  margin-bottom: auto;
  /* font-size:medium; */
}
.importArea{
  min-height: 100px;
  max-height: 90px;
  max-width: 200px;
}
.walletListBox{
  display: flex;
  flex-direction: column;
  padding-left: 1vmin;
  padding-right: 1vmin;
  color: white;
  border-color:bisque;

}
.walletSingle{
  display: flex;
  width: 100%;
  color: white;
  border-color:bisque;
  /* border-style: solid; */
  /* border-bottom-style: none;
  border-top-style: solid; */
}
.walletSingle-end{
  display: flex;
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.single-column{
  display: block;
  min-width: 20px;
  width: 30%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:dashed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  /* border-bottom-style: none; */
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.single-private{
  display: block;
  min-width: 20px;
  width: 13.8%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:dashed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.single-id{
  display: flex;
  min-width: 2.8%;
  max-width: 21%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:dashed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  border-top-style: solid;
  border-left-style: solid;
  color: white;
  border-color:bisque;
}
.single-balance{
  display: flex;
  min-width: 12.5%;
  max-width: 21%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:dashed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.single-approve{
  display: flex;
  min-width: 6%;
  max-width: 21%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:dashed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.single-approve-end{
  display: flex;
  min-width: 7%;
  max-width: 21%;
  resize: none;
  padding-left: 5px;
  padding-right: 5px;
  border-right-style:solid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  border-top-style: solid;
  color: white;
  border-color:bisque;
}
.inputName{
  font-size: 5px;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;
}
.btnAll{
  margin-top: 2px;
  margin-bottom: 2px;
  /* color: crimson; */
  font-style:oblique;
  font-size: large;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
